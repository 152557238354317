<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  data() {
    return {
      formConfig: {},
    };
  },
  props: {
    propsObj: Object,
  },
  mounted() {
    this.params = { applyCode: this.propsObj.row.applyCode, applyDetailCode: this.propsObj.row.detailCode };
    this.getConfigList('tpm_tasting_wine_order_management_detail');
  },
  methods: {

  },
};
</script>
