import BasePage from '../../../../../components/table_page';
import adjustmentForm from './adjustment';
import detailForm from './detail';
import receiveDetails from './receiving_details';
import openReward from './opening_reward';

export default {
  name: 'tasting_wine_order_management',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    adjustmentForm,
    detailForm,
    receiveDetails,
    openReward,
  },
  created() {
    this.getConfigList('tpm_tasting_wine_order_management_list');
  },
  methods: {
    cellClick({ row, column }) {
      if (column.property === 'adjustNum') {
        this.formName = 'detailForm';
        this.propsObjInData.row = row;
        this.modalConfig.title = '查看明细';
        this.openFull();
      }
    //   if (column.property === 'applyName') {
    //     this.formConfig = {
    //       type: 'view',
    //       id: row.id,
    //       disabled: true,
    //     };
    //     this.modalConfig.title = '查看';
    //     this.openFull();
    //   }
    },
    modalClick({ val, row }) {
      console.log(val);
      console.log(row);
      if (val.code === 'add' || val.code === 'edit' || val.code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      }
      if (val.code === 'adjustment') {
        this.formName = 'adjustmentForm';
        this.modalConfig.title = '调整';
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.openFull();
      }
      if (val.code === 'receiving_details') {
        console.log(row.applyCode);
        this.formName = 'receiveDetails';
        this.modalConfig.title = '收货明细';
        this.propsObjInData.row = row;
        this.openFull();
      }
      if (val.code === 'open_record') {
        console.log(row.applyCode);
        this.formName = 'openReward';
        this.modalConfig.title = '开瓶记录';
        this.propsObjInData.row = row;
        this.openFull();
      }
    },
  },
};
