<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import Storage from '@/found/utils/storage';

export default {
  extends: Form,
  props: {
    parentCode: String,
  },
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'tpm_tasting_wine_order_management_adjustment',
    };
  },

  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'num') { // 调整数量（瓶）
        item.props = {
          ...item.props,
          min: 0,
          precision: 0,
          controls: false,
        };
      }
      return item;
    },
    // 表单渲染完成后调用
    async formComplete() {
      this.disabled(true, ['createName']);
      this.setValue({
        createName: JSON.parse(Storage.l.get('userInfo')).fullName || '',
      });
    },
    // 表单提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        const url = '/tpm/tpmtastingapplyadjust/save';
        const params = { ...formData, applyCode: this.formConfig.row.applyCode, applyDetailCode: this.formConfig.row.detailCode };
        delete params.title1;
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
